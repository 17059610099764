import React from 'react';
import Footer from '../components/Footer'; // Adjust import path as needed
import Navbar from '../components/Navbar'; // Adjust import path as needed
import Kontaktformular from "../components/Kontaktformular";
import './Kontakt.css'; // Import the updated CSS

function Kontakt() {
  return (
    <>
      <Navbar />

      <div className="kp-kontakt-container">
        <h2 className="kp-info-title">Kontakt</h2>

        <button 
          className="kp-whatsapp-button" 
          onClick={() => window.open('https://wa.me/4972316032819', '_blank')}
        >
          <i className="fab fa-whatsapp"></i> WhatsApp Chat starten
        </button>

        <button 
          className="kp-call-button" 
          onClick={() => window.location.href='tel:072316032819'}
        >
          <i className="fas fa-phone"></i> Jetzt anrufen
        </button>

        <a 
          href="mailto:info@senphysio.de" 
          className="kp-email-button"
        >
          <i className="fas fa-envelope"></i> E-Mail senden
        </a>

        <Kontaktformular />

        <h2 className="kp-info-title">Öffnungszeiten</h2>

        <p className="kp-info-text">
          Montag, Mittwoch: <br /> 08:00 - 12:00 Uhr / 14:00 - 18:00 Uhr <br />
          Dienstag, Donnerstag, Freitag: <br /> 08:00 - 14:00 Uhr
        </p>
        
        <div className="kp-map-container">
          <iframe
            title="Praxis Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2623.2127280197897!2d8.693781099999999!3d48.8922829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479773f2bbed0329%3A0xc3d8ed955ee445cb!2sSen%20Physiotherapie!5e0!3m2!1sde!2sde!4v1725100023930!5m2!1sde!2sde"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <Footer className="kp-footer" />
    </>
  );
}

export default Kontakt;
