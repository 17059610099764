import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import './Kontaktformular.css';  // Optional: if you have custom CSS for styling

function Kontaktformular() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    telefon: "",
    nachricht: "",
  });
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [formStatus, setFormStatus] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      setFormStatus("Bitte lösen Sie das reCAPTCHA.");
      return;
    }

    try {
      const response = await fetch("/.netlify/functions/sendMail", {
        method: "POST",
        body: JSON.stringify({ ...formData, recaptchaValue }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setFormStatus("Nachricht erfolgreich gesendet!");
      } else {
        setFormStatus("Fehler beim Senden der Nachricht.");
      }
    } catch (error) {
      setFormStatus("Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.");
    }
  };

  return (
    <div className="kontaktformular-container">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">E-Mail:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="telefon">Telefon:</label>
          <input
            type="tel"
            id="telefon"
            name="telefon"
            value={formData.telefon}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="nachricht">Nachricht:</label>
          <textarea
            id="nachricht"
            name="nachricht"
            value={formData.nachricht}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <ReCAPTCHA
            sitekey="6Le4bjQqAAAAADJiXmeOPMnvklvSQnLquem-j9jA"
            onChange={setRecaptchaValue}
          />
        </div>
        <button type="submit">Nachricht senden</button>
        <p>{formStatus}</p>
      </form>
    </div>
  );
}

export default Kontaktformular;
