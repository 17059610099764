import React from 'react';
import Footer from '../components/Footer'; // Assuming you have this component
import Navbar from '../components/Navbar'; // Adjust the import path as needed
import './Galerie.css'; // If you have specific styles for this page


function Galerie() {
  return (
    <div className="galerie-container">
      <Navbar />

      
      <Footer />
    </div>
  );
}

export default Galerie;
